import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=2536118e&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"
import style0 from "./Main.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QParallax from 'quasar/src/components/parallax/QParallax.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QVideo,QBtn,QCard,QCardSection,QParallax,QImg,QCardActions,QBtnDropdown,QList,QItem,QItemLabel,QItemSection,QIcon});
