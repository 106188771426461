import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mixinDropbox } from "../components/mixinDropbox";
import { mixinGeneral } from "../components/mixinGeneral";
import { mixinSound } from "../components/mixinSound";
import RecordAudio from "../components/RecordAudio";
import RecordCamcord from "../components/RecordCamcord";
import folderCardDisplay from "../components/folderCardDisplay";
export default {
  components: {
    RecordCamcord: RecordCamcord,
    RecordAudio: RecordAudio,
    folderCardDisplay: folderCardDisplay
  },
  mixins: [mixinSound, mixinGeneral, mixinDropbox],
  computed: {
    myFolder: function myFolder() {
      return this.folders[this.activeFolder.path_lower];
    }
  },
  methods: {
    /*      play (folder, pageName) {
            pageName = pageName.toString()
            let targetPage
            if (pageName in this.myFolder) {
              targetPage = this.myFolder[pageName]
            }
            else {
              targetPage = this.myFolder.pages[pageName]
            }
             if (pageName) {
              this.playBookPage(folder, pageName)
            }
            else {
               if (window.jim_DEBUG_FULL) console.log('illustrate.page: PageName not found: ' + pageName)
            }
          }, */
    completedBulkNarration: function completedBulkNarration() {
      var last = parseInt(this.bulk.nextNarration);

      if (isNaN(last)) {
        this.bulk.nextNarration += '.1';
      } else {
        this.bulk.nextNarration = last + 1;
      }
    },
    completedBulkIllustration: function completedBulkIllustration() {
      var last = parseInt(this.bulk.nextIllustration);

      if (isNaN(last)) {
        this.bulk.nextIllustration += '.1';
      } else {
        this.bulk.nextIllustration = last + 1;
      }
    },
    narratePageAndroid: function narratePageAndroid(folder, pageName, offset, childName) {
      if (!this.activeRecorderOffset) {
        // start the recorder
        this.activeRecorderOffset = offset.toString(); // only one at a time

        var toggled = {};

        if (!this.activeNow[pageName]) {
          toggled[pageName] = !toggled[pageName];
        }

        this.$set(this, 'recording', toggled);
      } else {
        // close Recorder
        if (this.$refs['record_audio_' + (childName || offset.toString())]) {
          var child = this.$refs['record_audio_' + (childName || offset.toString())];

          if (child.length > 0) {
            child[0].doAction();
          }
        } else {
          if (window.jim_DEBUG_FULL) console.log('can not find record_audio_' + offset);
        }

        this.activeRecorderOffset = false;
        this.$set(this, 'recording', {});
      }
    },
    illustrate: function illustrate(folder, pageName) {
      this.$router.push('/Illustrate/' + pageName);
    },
    illustrateLocal: function illustrateLocal(folder, pageName) {
      // only one at a time
      var toggled = {};

      if (!this.illustrating[pageName]) {
        toggled[pageName] = !toggled[pageName]; // this.$q.fullscreen.request()
      }

      this.$set(this, 'illustrating', toggled);
    }
  },
  data: function data() {
    return {
      activeNow: {},
      activeRecorderOffset: false,
      recording: {},
      illustrating: {},
      bulk: {
        nextNarration: 0,
        nextIllustration: 0
      }
    };
  },
  mounted: function mounted() {
    window.jim = window.jim || {};
    window.jim.manage = this;
    this.bulk.nextNarration = parseInt(this.activeFolder.pageOrder[this.activeFolder.pageOrder.length - 1]) + 1 || 0;
    this.bulk.nextIllustration = this.bulk.nextNarration || 0;
  },
  name: 'manage'
};