//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mixinSound } from "../components/mixinSound";
import { mixinGeneral } from "../components/mixinGeneral";
export default {
  name: 'Carousel',
  mixins: [mixinGeneral, mixinSound],
  data: function data() {
    return {
      thumbnails: true,
      jim: 0,
      pendingTimeMark: false,
      colors: ['primary', 'secondary', 'yellow', 'red', 'orange', 'grey-2'],
      modal: true,
      thumbnailsHorizontal: false
    };
  },
  mounted: function mounted() {
    window.jim = window.jim || {};
    window.jim.carousel = this;
    this.myPages = this.pageOrder(this.activeFolder);
    this.sourcePages = this.folders[this.activeFolder.path_lower]; // this.currentBookThumbnails = this.myPages.map((p) => {
    //   return this.imageForPage(p)
    // })

    this.currentSlide = 0;
    this.waitAndStart(0);
  },
  methods: {
    waitAndStart: function waitAndStart(attempts) {
      var _this = this;

      console.log('waitAndStart ' + attempts);

      if (attempts < 20) {
        if (this.activeFolder && this.activeFolder.soundOrder[0]) {
          this.playBookPage(this.activeFolder, this.myPages[0]);
        } else {
          // wait a second and try again
          setTimeout(function () {
            _this.waitAndStart(attempts + 1);
          }, 1000);
        }
      }
    },
    backgroundStyle: function backgroundStyle(url) {
      return "background-image: url('".concat(url, "'); background-size: contain; background-position: 50% center;background-repeat: no-repeat;");
    },
    mouseDown: function mouseDown(event) {
      console.log('*********************** mouseDown'); // event.target.unselectable = true

      var vue = this;
      vue.pendingTimeMark = new Date();
    },
    mouseUp: function mouseUp(event) {
      console.log('*********************** mouseUp');
      var vue = this;
      var diff = new Date() - vue.pendingTimeMark;

      if (diff < 1000) {
        vue.$store.commit('delayPlayNext', 0);
        console.log('********************************************** Started play one ' + diff);
      } else {
        vue.$store.commit('delayPlayNext', 2000);
        console.log('********************************************** Changed to play after 2 seconds ' + diff);
      }

      this.playCurrent();
    }
  }
};