export default {
  dev: {
    mobile: 'Mobile',
    laptop: 'Laptop',
    noCamera: 'Desktop',
    remember: 'The Alamo',
    ref: '@:remember is in Texas'
  },
  'Take a selfie of you, the book, your kid': 'Take a selfie of you, the book, your kid',
  'Done': 'Done',
  'Selfie': 'Selfie',
  'click to keep': 'Click to Keep',
  'Carousel': 'Carousel',
  'Manage': 'Manage',
  'Refresh': 'Refresh',
  'Logout': 'Logout',
  'Docs': 'Docs',
  'Autoplay pages': 'Autoplay pages',
  'One page at a time': 'One page at a time',
  'Open Dropbox Folder': 'Open Dropbox Folder',
  'Immediately hear your Narration': 'Immediately hear your Narration',
  'Continue to end of book': 'Continue to end of book',
  'Waits for you to advance': 'Waits for you to advance',
  'Close this menu': 'Close this menu',
  'Close me': 'Close me',
  'manage sound and images for every page': 'manage sound and images for every page',
  'Reload from DropBox': 'Reload from DropBox',
  'See your files': 'See your files',
  'Log out from DropBox': 'Log out from DropBox',
  'Don\'t replay after recording': 'Don\'t replay after recording',
  'Replay after recording': 'Replay after recording',
  'Settings': 'Settings',
  'Image Quality': 'Image Quality',
  'Released': 'Released',
  'Illustrate': 'Illustrate',
  'Narrate': 'Narrate',
  'Play All': 'Play All',
  'Page': 'Page',
  'Playing': 'Playing',
  'Creating': 'Creating',
  'Silence': 'Silence',
  'play': 'play',
  'view': 'view',
  'images': 'Images',
  'narrate multiple new pages': 'narrate multiple new pages',
  'Illustrate multiple new pages': 'Illustrate multiple new pages',
  'Rename': 'Rename',
  'Delete  Book': 'Delete  Book',
  'erase recording': 'erase recording',
  'erase image': 'erase image',
  'Next Page to Narrate': 'Next Page to Narrate',
  'Next': 'Next',
  'Book Title': 'Book Title',
  'Previous': 'Previous',
  'Cancel': 'Cancel',
  'Illustrating the whole book (Take a snapshot of each page)': 'Illustrating the whole book (Take a snapshot of' + ' each page)',
  'Narrating the whole book (Read each page)': 'Narrating the whole book (Read each page)',
  'Work page-by-page (Narrate and Illustrate a page at a time)': 'Work page-by-page (Narrate and Illustrate a page' + ' at a time)',
  'NO RECORDING YET': 'NO RECORDING YET',
  'Recording Component present': 'Recording Component present',
  'keep as page': 'keep as page',
  'file': 'file',
  'done': 'done',
  'Switch:Rear': 'Switch:Rear',
  'Switch:front': 'Switch:front',
  'Stop': 'Stop',
  'Saving... page': 'Saving... page',
  'Not Ready to Record': 'Not Ready to Record',
  'mic variable not set?': 'mic variable not set?',
  'Replace Selfie': 'Replace Selfie',
  'No Image': 'No Image',
  'intro_function': 'We help you record books for your loved ones to play back.\n' + '      This free version uses <em>your</em> DropBox account to store images and recordings. \n' + '<br><br>' + '      This means you always will have your originals, and that we never even see anything you create.',
  'whole idea': '<div><p> So, the next time you’re reading a favorite book to your child, you grab your cell phone' + ' and go to <b>Play It Again Kid</b>. You can <b>take a selfie of both of you, plus the book</b>. Then <b>record your reading</b>, page by page. </p><p> Now this can be <b>played back</b> on a tablet, phone or PC, any time they wish just by clicking on that selfie. It can play <b>straight through, or page by page</b> while the babysitter interacts over the illustrations. After your child plays it,<b> you can get an email</b> so you know they spent some time with your recording, and they can press a button to <b>record a message</b>, a picture of themselves, or a drawing by them, which is <b>sent to you</b> and <b>saved for posterity</b>. Maybe they wake up from a noise at night, and turn to the recording to <b>comfort themselves back to sleep</b> – now you’ll know. </p><p> And if they are learning to read, they can play it back word by word and <b>learn to sight read to your voice</b>, at their own speed. </p><p> On any page they want, you can prompt them to <b>draw what is happening</b>, retell you the story, or <b>take a picture of them</b> acting that page out in real life. <b>All saved permanently</b> so you can see their progress as they develop. Before you know it, your child’s favorite literature has become <b>a rich multi-media creative interaction</b> - fun to watch their own version of the book, saved for them to play to other family members. </p></div>',
  'Login with Dropbox': 'Login',
  'intro_signin': 'If you are not a current user, you may want to create a free account on ' + '<a href="https://www.dropbox.com/register"     targt="_blank">their site first</a>. ' + 'Or, install their app.',
  'intro_disclaimer': 'This website or app is not affiliated with or otherwise sponsored by Dropbox, Inc.',
  'Make your first book': 'Make your first book',
  'first_book_intro': '<p>The menu at the left has an entry, "Make a new book." </p>\n' + '\n' + '        <p>You can open the menu, if it is closed, by hitting the icon\n' + '          <i aria-hidden="true" class="q-icon material-icons">menu</i>  in the top bar\n' + '        </p>\n' + '\n' + '        <p>To take a picture, when you can see the camera active on the screen, you can take a picture by hitting\n' + '          anywhere on the screen. You can always replace it if you want a second chance.</p>',
  'Create a new book': 'Create a new book',
  'Cover Selfie': 'Cover Selfie',
  'Title': 'Title',
  'Tags': 'Tags',
  'Page Numbering': 'Page Numbering',
  'Plan': 'Next',
  'lock_UI': 'Lock',
  'unlock_UI': 'Unlock',
  'List of Books': 'List of Books',
  'Or click the top bar': 'Or click the top bar',
  'Enable Editing Books': 'Enable Editing Books',
  'Disable Editing Books': 'Disable Editing Books',
  'Page By Page': 'Page By Page',
  'Touch image to take selfie': 'Touch image to take',
  'You can retake the cover': 'Touch screen to retake the cover',
  'Retake': 'Retake',
  'Take': 'Take',
  'You can start by': 'You can start by',
  'At any time, you can stop what you are doing, and restart in any way you like': 'At any time, you can stop what you are doing, and restart in any way you like',
  'narrate page': 'narrate page',
  message: {
    menu: {
      newBook: 'Make a new book'
    },
    note: {
      takeImage: {
        noCamera: 'Select Image for {title}',
        laptop: 'Image for {title}',
        mobile: 'Image for {title}',
        general: 'Image for {title}'
      },
      reuseImage: {
        noCamera: 'Select Image for {title}',
        laptop: 'Image for {title}',
        mobile: 'Image for {title}',
        general: 'Image for {title}'
      },
      complete: {
        noCamera: 'Add Image to {title}',
        laptop: 'Add Image to {title}',
        mobile: 'Add Image to {title}',
        general: 'Add Image to {title}'
      }
    },
    book: {
      takeImage: {
        noCamera: 'Select Selfie of listener, reader, and book',
        laptop: 'Take Selfie of listener, reader, and book',
        mobile: 'Take Selfie of listener, reader, and book',
        general: 'Take Selfie of listener, reader, and book'
      },
      reuseImage: {
        noCamera: 'Select Selfie of listener, reader, and book',
        laptop: 'Find Image',
        mobile: 'Find Image',
        general: 'Find Image'
      },
      complete: {
        noCamera: 'Create Book',
        laptop: 'Create Book',
        mobile: 'Create Book',
        general: 'Create Book'
      }
    }
  }
};