/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import Vue from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import Ripple from 'quasar/src/directives/Ripple.js';import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';import TouchPan from 'quasar/src/directives/TouchPan.js';import TouchHold from 'quasar/src/directives/TouchHold.js';import Dialog from 'quasar/src/plugins/Dialog.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Notify from 'quasar/src/plugins/Notify.js';;
Vue.use(Quasar, {
  config: {},
  directives: {
    Ripple: Ripple,
    TouchSwipe: TouchSwipe,
    TouchPan: TouchPan,
    TouchHold: TouchHold
  },
  plugins: {
    Dialog: Dialog,
    AppFullscreen: AppFullscreen,
    LocalStorage: LocalStorage,
    Notify: Notify
  }
});