import { render, staticRenderFns } from "./RecordCamcord.vue?vue&type=template&id=4cfea314&"
import script from "./RecordCamcord.vue?vue&type=script&lang=js&"
export * from "./RecordCamcord.vue?vue&type=script&lang=js&"
import style0 from "./RecordCamcord.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab});
