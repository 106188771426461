import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.string.link";
import vue from 'vue';
import { Howl } from 'howler';
export function playHowl(state, payload) {
  var killLength = state.playing.length;
  state.playing.push(payload.page);
  payload.page.howl.play();
  state.mostRecentPage = payload.page.pageNumber;

  for (var killing = 0; killing < killLength; killing++) {
    state.playing.shift();
  }
}
export function playHowlAlso(state, payload) {
  state.playing.push(payload.page);
  payload.page.howl.play();
}
export function silence(state) {
  var killing = state.playing.pop();

  while (killing) {
    killing.next = function () {
      console.log('SILENCE killing: ' + killing.fname);
    };

    killing.howl.stop();
    killing = state.playing.pop();
  }
}
export function endHowlPlay(state, payload) {
  state.playing.forEach(function (howl) {
    howl.howl.stop();
  });
}
export function delayPlayNext(state, ms) {
  console.log('delayPlayNext: ' + ms);
  vue.set(state, 'delayPlayNext', ms);
}
export function howlPreload(state, value) {
  state.howlPreload = value;
}
export function createHowl(state, payload) {
  var which = payload.ids[payload.entry.id];
  var src = payload.response.link;
  var formats = [];

  if (payload.entry && payload.entry.parts && payload.entry.parts.ext) {
    formats.push(payload.entry.parts.ext.replace('.', ''));
  } else {
    console.log('guessing the format for : ' + which);
    formats = ['m4a'];
  }

  console.log('FORMATS: ' + JSON.stringify(formats));

  if (which) {
    // TODO: m4a may not be the only format!  Must be more rigorous
    var newHowl = new Howl({
      src: [src],
      format: formats,
      xhtml5: true,
      preload: payload.howlPreload || false
    });
    vue.set(which, 'howl', newHowl); // newHowl.state = state

    newHowl.page = which;
    newHowl.on('load', function () {
      console.log('HOWL loaded page ' + which.pageNumber || which.page || '');
    });
    newHowl.on('end', function () {
      // remove myself from playing
      var killLength = state.playing.length;

      for (var killing = 0; killing < killLength; killing++) {
        state.playing.shift();
      }

      which.next();
    }); // vue.set(which, 'metadata', payload.response.metadata)
  } else {
    if (window.jim_DEBUG_VUEX) console.log('Fail create howl: ');
    if (window.jim_DEBUG_VUEX) console.dir(payload);
  }
}
export function mediaState(state, payload) {
  state.mediaState = payload;
}
export function setDevices(state, payload) {
  state.devices = payload;
}
export function setCurrentVideo(state, payload) {
  state.currentVideo = payload;
}