//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mixinGeneral } from "../components/mixinGeneral";
export default {
  mixins: [mixinGeneral],
  name: 'folderCardDisplay',
  props: ['folder'],
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    window.jim = window.jim || {};
    window.jim.folderCard = this;
  }
};