import uid from 'quasar/src/utils/uid.js';;
var dataLayer = window.dataLayer || [];
export default {
  logEvent: function logEvent(category, action, label, value) {
    if (process.env.DEV) {
      console.log(JSON.stringify(['gtm', category, action, label, value]));
    }

    dataLayer.push({
      'event': 'customEvent',
      'category': category,
      'action': action,
      'label': label,
      'value': value,
      'cid': this.getCid()
    });
  },
  logPage: function logPage(path) {
    // Here you can preprocess the path, if needed
    dataLayer.push({
      'event': 'customPageView',
      'path': path,
      'cid': this.getCid()
    });
  },
  getCid: function getCid() {
    // We need an unique identifier for this session
    // We store it in a localStorage, but you may use cookies, too
    if (!localStorage.cid) {
      localStorage.cid = uid();
    }

    return localStorage.cid;
  }
};