import VueI18n from 'vue-i18n'; // import messages from 'src/i18n'

import langEn from "../statics/i18n/en.js";
export default (function (_ref) {
  var app = _ref.app,
      Vue = _ref.Vue;
  Vue.use(VueI18n); // Set i18n instance on app

  app.i18n = new VueI18n({
    locale: 'en-us',
    fallbackLocale: 'en-us',
    messages: {
      'en-us': langEn
    }
  });
});