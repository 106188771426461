import multiguard from 'vue-router-multiguard';
import Store from "../store";
import MyLayout from "../layouts/MyLayout.vue";
import Main from "../pages/Main.vue";
import Selfie from "../pages/Selfie.vue";
import Carousel from "../pages/Carousel.vue";
import Illustrate from "../pages/Illustrate.vue";
import Narrate from "../pages/Narrate.vue";
import Manage from "../pages/Manage.vue";
import Error404 from "../pages/Error404.vue";
import newBook from "../pages/newBook";
var store = Store();

var guardActiveFolder = function guardActiveFolder(to, from, next) {
  // TODO: this invalidates bookmarks.  Allow bookmarks into specific pages of books #16hrs
  if (store.state.groups.activeFolder) {
    next();
  } else {
    next('/');
  }
};

var noActiveFolder = function noActiveFolder(to, from, next) {
  if (store.state.groups.activeFolder) {
    store.dispatch('setActiveFolder', {
      activeFolder: false
    });
  }

  next();
};

var routes = [{
  path: '/',
  component: MyLayout,
  children: [{
    path: '',
    component: Main
  }],
  beforeEnter: multiguard([noActiveFolder])
}, {
  path: '/auth',
  component: MyLayout,
  children: [{
    path: '',
    component: Main
  }]
}, {
  path: '/selfie',
  component: MyLayout,
  children: [{
    path: '',
    component: Selfie
  }],
  beforeEnter: multiguard([])
}, {
  path: '/book/new/step/:step',
  component: MyLayout,
  children: [{
    path: '',
    name: 'newbook',
    component: newBook
  }],
  beforeEnter: multiguard([noActiveFolder])
}, {
  path: '/carousel',
  component: MyLayout,
  children: [{
    path: '',
    component: Carousel
  }],
  beforeEnter: multiguard([guardActiveFolder])
}, {
  path: '/Illustrate/:pageName',
  props: true,
  component: MyLayout,
  children: [{
    path: '',
    name: 'illustratePage',
    component: Illustrate
  }],
  beforeEnter: multiguard([guardActiveFolder])
}, {
  path: '/Illustrate',
  component: MyLayout,
  children: [{
    path: '',
    component: Illustrate
  }],
  beforeEnter: multiguard([guardActiveFolder])
}, {
  path: '/Narrate/:pageName',
  props: true,
  component: MyLayout,
  children: [{
    path: '',
    component: Narrate
  }],
  beforeEnter: multiguard([guardActiveFolder])
}, {
  path: '/Narrate',
  component: MyLayout,
  children: [{
    path: '',
    component: Narrate
  }],
  beforeEnter: multiguard([guardActiveFolder])
}, {
  path: '/manage',
  component: MyLayout,
  children: [{
    path: '',
    component: Manage
  }],
  beforeEnter: multiguard([guardActiveFolder])
}, {
  path: '/:access_token(.*)',
  component: MyLayout,
  name: 'returnDropbox',
  children: [{
    path: '',
    component: Main
  }],
  beforeEnter: multiguard([noActiveFolder])
}]; // Always leave this as last one

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: Error404
  });
}

export default routes;