import { render, staticRenderFns } from "./Illustrate.vue?vue&type=template&id=d1aa57de&"
import script from "./Illustrate.vue?vue&type=script&lang=js&"
export * from "./Illustrate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QField from 'quasar/src/components/field/QField.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QField,QInput,QBtn,QOptionGroup,QImg,QBadge});
