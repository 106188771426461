//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mixinGeneral } from "../components/mixinGeneral";
import { mixinSound } from "../components/mixinSound";
import openURL from 'quasar/src/utils/open-url.js';;
import v from "../statics/version.json";
import MenuTutorials from 'src/components/MenuTutorials';
export default {
  name: 'MyLayout',
  mixins: [mixinSound, mixinGeneral],
  computed: {
    alwaysTrue: {
      get: function get() {
        return true;
      },
      set: function set(value) {}
    },
    leftDrawerOpenOrNoBooks: {
      get: function get() {
        return this.TOCSorted.length === 0 || this.leftDrawerOpen;
      },
      set: function set(value) {
        this.leftDrawerOpen = value;
      }
    }
  },
  components: {
    MenuTutorials: MenuTutorials
  },
  data: function data() {
    return {
      leftDrawerOpen: false,
      // this.TOCSorted.length === 0,
      rightDrawerOpen: false,
      devFeedback: false,
      // process.env.DEV,
      version: v.version
    };
  },
  methods: {
    openURL: openURL,
    toggleLeftDrawer: function toggleLeftDrawer() {
      this.leftDrawerOpen = !this.leftDrawerOpen;
    }
  },
  mounted: function mounted() {
    window.jim = window.jim || {};
    window.jim.layout = this;
    this.$root.$on('reload', this.readDropboxFolder); // this.leftDrawerOpen = this.TOCSorted.length === 0
    // $root.$emit('reload')
  }
};