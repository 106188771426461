import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=ad9ea79e&"
import script from "./Carousel.vue?vue&type=script&lang=js&"
export * from "./Carousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QCarousel,QCarouselSlide,QImg,QCarouselControl});
