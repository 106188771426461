import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import _defineProperty from "/Volumes/My Passport for Mac/jim backup from hard/Dev/local/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mixinGeneral } from "../components/mixinGeneral";
import { mixinDropbox } from "../components/mixinDropbox";
import { mixinIllustrate } from "../components/mixinIllustrate";
import RecordCamcord from "../components/RecordCamcord";
import { mapGetters } from 'vuex';
export default {
  name: 'Illustrate',

  /* props: ['pageName'], */
  components: {
    RecordCamcord: RecordCamcord
  },
  mixins: [mixinGeneral, mixinDropbox, mixinIllustrate],
  // mixins: [mixinGeneral, mixinDropbox],
  data: function data() {
    return {
      pageName: '',
      action: 'click to keep',
      imageTaken: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['videoDevicesAsOptions', 'currentVideo'])), {}, {
    activeDevice: {
      get: function get() {
        return this.currentVideo;
      },
      set: function set(val) {
        this.$store.commit('setCurrentVideo', val);
      }
    }
  }),
  mounted: function mounted() {
    window.jim = window.jim || {};
    window.jim.Illustrate = this;

    if (this.$route.params.pageName) {
      this.pageName = this.$route.params.pageName;
    } else {
      this.pageName = this.nextIllustration(this.activeFolder);
    }
  },
  methods: {
    triggerRecordCamcord: function triggerRecordCamcord() {
      this.$refs.recordCamCord.touchImageFeedback();
    },
    onImage: function onImage() {
      console.log('Image was taken');
      this.pageName = (parseInt(this.pageName) + 1).toString();
    }
  }
};