export default {
  _TOC: {},
  folders: {},
  // TODO: groups
  ids: {},
  // Does this really belong to dropbox?
  activeFolder: false,
  // TODO: activeGroup
  activeScene: false // 0 based carousel offset

};