/* eslint-disable camelcase */
export function access_token(state) {
  return state.access_token;
}
export function token_type(state) {
  return state.token_type;
}
export function uid(state) {
  return state.uid;
}
export function account_id(state) {
  return state.account_id;
}
export function thumbnailSize(state) {
  return state.thumbnailSize;
}
export function thumbnailSizes(state) {
  return state.thumbnailSizes;
}
export function quality(state) {
  return state.quality;
}
export function _TOC(state) {
  return state._TOC;
}
export function ids(state) {
  return state.ids;
}
export function folders(state) {
  return state.folders;
}
export function thumbnails(state) {
  return state.thumbnails;
}